import * as THREE from "/node_modules/three";
import { EffectComposer } from "/node_modules/three/examples/jsm/postprocessing/EffectComposer.js";
import {CopyShader} from '/node_modules/three/examples/js/shaders/CopyShader';
import { RenderPass } from "/node_modules/three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "/node_modules/three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { ShaderPass } from '/node_modules/three/examples/jsm/postprocessing/ShaderPass.js';
import { SMAAPass } from '/node_modules/three/examples/jsm/postprocessing/SMAAPass.js';
import { gsap } from '/node_modules/gsap';
import '/src/style.css';
import { GLTFLoader } from '/node_modules/three/examples/jsm/loaders/GLTFLoader.js';
import { ScrollTrigger } from "/node_modules/gsap/ScrollTrigger.js";
import { ScrollSmoother } from "/node_modules/gsap/ScrollSmoother.js";
import { DotScreenPass } from '/node_modules/three/examples/jsm/postprocessing/DotScreenPass.js';
import {OrbitControls} from "/node_modules/three/examples/jsm/controls/OrbitControls.js";
import { TransformControls } from "/node_modules/three/examples/jsm/controls/TransformControls.js";
import { BokehPass } from '/node_modules/three/examples/jsm/postprocessing/BokehPass.js';
import Stats from 'stats.js';
import { Raycaster } from "three";
import { render } from "react-dom";


//without this line, may get dropped by your bundler (tree shaking)...
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);



//global declaration
const canvas = document.getElementsByTagName("canvas")[0];
let camera;
let renderer;
let mixer = null;
var scene = new THREE.Scene();
const fov = 60;
const aspect = window.innerWidth / window.innerHeight;
const near = 0.1;
const far = 900;
var ww = window.innerWidth;
var wh = window.innerHeight;
ScrollTrigger.config({ ignoreMobileResize: true });
ScrollTrigger.normalizeScroll(true);
var controls;




//=======================================     TECH    =============================================


//IOS GESTURE LOCK

document.addEventListener('gesturestart', function(e) {
  e.preventDefault();
  // special hack to prevent zoom-to-tabs gesture in safari
  document.body.style.zoom = 0.99;
});

document.addEventListener('gesturechange', function(e) {
  e.preventDefault();
  // special hack to prevent zoom-to-tabs gesture in safari
  document.body.style.zoom = 0.99;
});

document.addEventListener('gestureend', function(e) {
  e.preventDefault();
  // special hack to prevent zoom-to-tabs gesture in safari
  document.body.style.zoom = 0.99;
});



//MATH

var Mathutils = {
    normalize: function($value, $min, $max) {
        return ($value - $min) / ($max - $min);
    },
    interpolate: function($normValue, $min, $max) {
        return $min + ($max - $min) * $normValue;
    },
    map: function($value, $min1, $max1, $min2, $max2) {
        if ($value < $min1) {
            $value = $min1;
        }
        if ($value > $max1) {
            $value = $max1;
        }
        var res = this.interpolate(this.normalize($value, $min1, $max1), $min2, $max2);
        return res;
    }
};
var markers = [];


//=======================================     LOADING SCREEN    =============================================

//BUTTON
let startbutton = document.querySelector('button');
gsap.to(startbutton, {autoAlpha:0 ,duration: 0, delay: 0});
startbutton.onclick = function doSomething(){
  gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
  gsap.to(startbutton, {autoAlpha:0})
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  sound.play()
  window.dispatchEvent(new Event('resize'));
  animate();
}
$('button').click(function(){
  $(this).remove();
});


//LOGOSCREEN
let logoscreendiv1 = document.querySelector('div1');
let logoscreenimg = document.querySelector('img');



//LOADERS
 let sceneReady = false
 const loadingBarElement = document.querySelector('.loading-bar')
 const loadingManager = new THREE.LoadingManager(
     // Loaded
     () =>
     {
         // Wait a little
         window.setTimeout(() =>
         {
             // Animate overlay
            //  gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
             // Update loadingBarElement
             loadingBarElement.classList.add('ended')
             loadingBarElement.style.transform = ''
         }, 500)

         window.setTimeout(() =>
         {
             // Animate button 
            //  gsap.to(startbutton.style.opacity, { duration: 1, value: 1, delay: 1})
             gsap.to(startbutton, {autoAlpha:1, duration:1, delay:1 });
             logoscreendiv1.remove();
             logoscreenimg.remove();
            //  gsap.to(startbutton, 2, {autoAlpha:1, delay:2});
         }, 500)
         
         window.setTimeout(() =>
         {
             sceneReady = true
         }, 2000)
     },
 
     // Progress
     (itemUrl, itemsLoaded, itemsTotal) =>
     {
         // Calculate the progress and update the loadingBarElement
         const progressRatio = itemsLoaded / itemsTotal
         loadingBarElement.style.transform = `scaleX(${progressRatio})`
     }
 )
 var gltfLoader = new GLTFLoader(loadingManager)
 const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
 

 
 //OVERLAY
 const overlayGeometry = new THREE.PlaneGeometry(5, 5, 1, 1)
 const overlayMaterial = new THREE.ShaderMaterial({
     wireframe: false,
     transparent: true,
     uniforms:
     {
         uAlpha: { value: 1 }
     },
     vertexShader: `
         void main()
         {
             gl_Position = vec4(position, 1.0);
         }
     `,
     fragmentShader: `
         uniform float uAlpha;
 
         void main()
         {
             gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
         }
     `
 })
 const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
 overlay.position.set(0, 0, -70);
 scene.add(overlay);




//=======================================     MODELS    =============================================

//INTRO
let intro = null
gltfLoader.load('/3d/intro/intro.gltf',
    (gltf) =>
      {

        intro = gltf.scene;
        intro.scale.set(0.5, 0.5, 0.5)
        intro.position.set(0, 0, -20)
        intro.rotation.set(6.2, 3.15, 0)
        intro.userData = { URL: "http://stackoverflow.com"};

          scene.add(intro);
    }
  )


//CARDS
//1
  let card1 = null
  gltfLoader.load('/3d/intro/card1facchinetti.gltf',
  (gltf) =>
    {
      card1 = gltf.scene;
      card1.scale.set(1, 1, 1)
      card1.position.set(0, -1.8, 50)
      card1.rotation.set(6.2, 3.15, 0)

        scene.add(card1);
  }
)

//2
let card2 = null
gltfLoader.load('/3d/intro/card2mute.gltf',
(gltf) =>
  {
    card2 = gltf.scene;
    card2.scale.set(1, 1, 1)
    card2.position.set(-30, -1.8, 100)
    card2.rotation.set(6.2, 3.15, 0)

      scene.add(card2);
}
)

//3
let card3 = null
gltfLoader.load('/3d/intro/card3bacini.gltf',
(gltf) =>
  {
    card3 = gltf.scene;
    card3.scale.set(1, 1, 1)
    card3.position.set(30, -1.8, 150)
    card3.rotation.set(6.2, 3.15, 0)

      scene.add(card3);
}
)

//4
let card4 = null
gltfLoader.load('/3d/intro/card4longoni.gltf',
(gltf) =>
  {
    card4 = gltf.scene;
    card4.scale.set(1, 1, 1)
    card4.position.set(0, 30, 200)
    card4.rotation.set(6.2, 3.15, 0)

      scene.add(card4);
}
)

//5
let card5 = null
gltfLoader.load('/3d/intro/card5osti.gltf',
(gltf) =>
  {
    card5 = gltf.scene;
    card5.scale.set(1, 1, 1)
    card5.position.set(0, -30, 250)
    card5.rotation.set(6.2, 3.15, 0)

      scene.add(card5);
}
)

//ZONA 2
gltfLoader.load('/3d/intro/lock.gltf',
    (gltf) =>
      {

        var lock = gltf.scene;
        gltf.scene.scale.set(2, 2, 2)
        gltf.scene.position.set(0, -40, 600)
        gltf.scene.rotation.set(6.2, 3.15, 0)

          // Animation
          mixer = new THREE.AnimationMixer(gltf.scene)
          const action0 = mixer.clipAction(gltf.animations[0])
          action0.play()
          scene.add(lock);
          // window.lockg = lock;
    }
  )


//GALAXY
// galaxy geometry
const starGeometry = new THREE.SphereGeometry(80*6, 64*6, 64*6);


// galaxy material
const starMaterial = new THREE.MeshBasicMaterial({
  map: THREE.ImageUtils.loadTexture("texture/galaxy1.png"),
  side: THREE.BackSide,
  transparent: false,
});
//2
const starMaterial2 = new THREE.MeshBasicMaterial({
  map: THREE.ImageUtils.loadTexture("texture/galaxy2.png"),
  side: THREE.DoubleSide,
  transparent: false,
});

//galaxy meshes
const starMesh = new THREE.Mesh(starGeometry, starMaterial);
const starMesh2 = new THREE.Mesh(starGeometry, starMaterial2);

starMesh2.scale.set(0.5, 0.5, 0.5);
starMesh2.position.set(40, -30, 750);
starMesh2.rotation.set(90, 90 ,90);

scene.add(starMesh);
scene.add(starMesh2);


//stars
function addStar() {
  const geometry = new THREE.SphereGeometry(0.12, 12, 12);
  const material = new THREE.MeshPhongMaterial({emissive: 0xffffff });
  const star = new THREE.Mesh(geometry, material);

  const [x, y, z] = Array(3)
    .fill()
    .map(() => THREE.MathUtils.randFloatSpread(500));

  star.position.set(x, y, z);
  scene.add(star);
}

Array(500).fill().forEach(addStar);




//=======================================     RESIZE    =============================================

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

//resize listner
window.addEventListener( 'resize', function () {
  const width = window.innerWidth;
  const height = window.innerHeight;

  
  camera.aspect = width / height;
	camera.updateProjectionMatrix();
  
  renderer.setSize( width, height );
  effectComposer.setSize( width, height );
}, true );


//=======================================     CAMERA    =============================================



//Create a perpsective camera
var cameraRotationProxyX = 0;
var cameraRotationProxyY = 0;
camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
camera.rotation.y = cameraRotationProxyX;
camera.rotation.z = cameraRotationProxyY;

//camera.position.z = 400;
var c = new THREE.Group();
c.position.z = 0;

c.add(camera);
scene.add(c);

//default renderer
renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  powerPreference: 'high-performance',
  antialias: false,
});
renderer.autoClear = false;
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1));
renderer.setClearColor(0x000000, 0.0);
renderer.shadowMap.enabled = false;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;


//POSTPROCESSING
//effectcomposer
const effectComposer = new EffectComposer(renderer)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
effectComposer.setSize(window.innerWidth, window.innerHeight)
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass);


//dotscreenpass
const dotScreenPass = new DotScreenPass()
dotScreenPass.enabled = false;
effectComposer.addPass(dotScreenPass);


// bloompass
const unrealBloomPass = new UnrealBloomPass();
// unrealBloomPass.enabled = false;
unrealBloomPass.strength = 0.5;
unrealBloomPass.radius = 0;
unrealBloomPass.threshold = 0;
effectComposer.addPass(unrealBloomPass);


//bookeh
const bokehPass = new BokehPass(scene, camera, {
  focus: 0.001,
  aperture: 0.00002,
  maxblur: 0.002,
  width: window.innerWidth,
  height: window.innerHeight,
})
// bokehPass.enabled = false;
effectComposer.addPass(bokehPass);




//=======================================     LIGHTS    =============================================


    
var light = new THREE.DirectionalLight( 0xffffff, 1 );
light.position.set( 10, 30, 50 );
scene.add( light );



var light3 = new THREE.PointLight( 0xffffff, 2, 100 );
light3.position.set( -30, -50, 780);
scene.add( light3 );



//fog
{
  const color = 0xDA532C;
  const density = 0.0004;
  scene.fog = new THREE.FogExp2(color, density);
  // scene.fog = new THREE.Fog(0x194794,0,100);
}




//=======================================     NAVIGATION    =============================================



//Array of coords
var coords = [
  [0, -60, -4],
  [0, 0, 1],
  [0, 100, 0],
  [0, 400, 0],
  [0, 600, 20],
  [0, 720, -50],
  [0, 800, -50],
  [90, 800, -50],
  [100, 800, 10],
  [280, 920, 50],
];

var p1, p2;

//Convert the array of coords into vertices
for (var i = 0; i < coords.length; i++) {
  var x = coords[i][0];
  var y = coords[i][2];
  var z = coords[i][1];
  coords[i] = new THREE.Vector3(x, y, z);
}
//Create a path from the coords
var path = new THREE.CatmullRomCurve3(coords);
//path.curveType = 'catmullrom';
path.tension = .5;


//WIREFRAME
//Create a new geometry with a different radius
var wireframe = new THREE.TubeGeometry( path, 150, 3.4, 32, false );
var geo = new THREE.EdgesGeometry( wireframe );
//THREE.EdgesGeometry( geometry );
var mat = new THREE.LineBasicMaterial( {
  linewidth: 2,
  opacity: .2,
  transparent: 1
} );
var wireframe = new THREE.LineSegments( geo, mat );
// scene.add( wireframe );


function updateCameraPercentage(percentage) {
  p1 = path.getPointAt(percentage%1);
  p2 = path.getPointAt((percentage + 0.03)%3);
  // p3 = path.getPointAt((percentage + 0.05)% 1);
  c.position.set(p1.x,p1.y,p1.z);
  c.lookAt(p2);
  // light.position.set(p2.x, p2.y, p2.z);
}
var cameraTargetPercentage = 0;
var currentCameraPercentage = 0;


gsap.to(tl, { duration: 1, ease: "none", y: -500 });
var tubePerc = {
  percent: 0
}


var tl = gsap.timeline({
  scrollTrigger: {
    trigger: ".scrollTarget",
    start: "top top",
    end: "bottom 100%",
    scrub: 1,
    // invalidateOnRefresh: true
  }
})
tl.to(tubePerc, {
   percent:.92,
  //  ease: easeNone,
   duration: 10,
   onUpdate: function() {
     cameraTargetPercentage = tubePerc.percent;
   }
});


var h = document.documentElement,
	 b = document.body,
	 st = 'scrollTop',
	 sh = 'scrollHeight',
	 htop = document.querySelector('.progress-bar.horizontal.top'),
	 hbot = document.querySelector('.progress-bar.horizontal.bottom'),
	 vleft = document.querySelector('.progress-bar.vertical.left'),
	 vright = document.querySelector('.progress-bar.vertical.right'),
	 scroll;

document.addEventListener('scroll', function() {
	console.log(h, b);
	// let's calculate the scroll position
	// ( body.scrollTop / ( body.scrollHeight - document.height ) ) * 100
	// multiplying by 100 here gives us a percent value
	scroll = ( h[st] || b[st] ) / ( ( h[sh] || b[sh] ) - h.clientHeight ) * 100;
	
	// set our variable for CSS to use
	htop.style.setProperty('--scroll', scroll + '%');
	hbot.style.setProperty('--scroll', scroll + '%');
	vleft.style.setProperty('--scroll', scroll + '%');
	vright.style.setProperty('--scroll', scroll + '%');
});



//=======================================     AUDIO    =============================================


// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
camera.add( listener );

// create a global audio source
const sound = new THREE.Audio( listener );

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader();
audioLoader.load( '/audio/1.mp3', function( buffer ) {
	sound.setBuffer( buffer );
	sound.setLoop( true );
	sound.setVolume( 0.1 );
  
});


//=======================================     AUDIO TOGGLE    =============================================


let soundon = true
let soundoff = false

//BUTTON
let audiotoggle = document.querySelector('label')
audiotoggle.onpointerdown = function toggle(){
  if (soundon == true) {
    soundon = false
    soundoff = true
    console.log("soundoff")
    sound.setVolume(0)
  } else if (soundon == false) {
    soundon = true
    soundoff = false
    console.log("soundon")
    sound.setVolume(0.1)
  }
}





//=======================================     TECH 2    =============================================



//clock
const clock = new THREE.Clock()
 let previousTime = 0
 
 const tick = () =>
 {
    //  stats.begin()
     const elapsedTime = clock.getElapsedTime()
     const deltaTime = elapsedTime - previousTime
     previousTime = elapsedTime


     // Model animation
     if(mixer !== null)
     {
         mixer.update(deltaTime)
     }
        // Call tick again on the next frame
        window.requestAnimationFrame(tick)

        // stats.end()
        // console.log(renderer.info)
    }
    tick()


$('canvas').click(function(){
  console.clear();
  markers.push(p1);
  console.log(JSON.stringify(markers));
});



//camera rotation
$(document).mousemove(function(evt) {
  cameraRotationProxyX = Mathutils.map(evt.clientX, 0, window.innerWidth, 0.5, -0.5);
  cameraRotationProxyY = Mathutils.map(evt.clientY, 0, window.innerHeight, 0.5, -0.5);
});



//animation loop
const animate = () => {
  requestAnimationFrame(animate);
  // cloud.rotation.y -= 0.0002;
  // moonPivot.rotation.y -= 0.001;
  // moonPivot.rotation.x = 0.001;
  // cameraPivot.rotation.y += 0.000;
  starMesh.rotation.y += 0.0002;
  starMesh2.rotation.y += 1;
  effectComposer.render();
  renderer.render(scene, camera);
  // renderer.render(scene, camera);


  currentCameraPercentage = cameraTargetPercentage
  
  camera.rotation.y += (cameraRotationProxyX - camera.rotation.y) / 15;
  camera.rotation.x += (cameraRotationProxyY - camera.rotation.x) / 15;
  
  updateCameraPercentage(currentCameraPercentage);
	


  const t = Date.now() * 0.001;
  const rx1 = Math.sin(t * 0.7) * 0.5;
  const ry1 = Math.sin(t * 0.3) * 0.5;
  const rz1 = Math.sin(t * 0.2) * -0.5;

  const rx2 = Math.sin(t * 0.2) * 0.4;
  const ry2 = Math.sin(t * 0.3) * 0.4;
  const rz2 = Math.sin(t * 0.2) * 0.4;

  const rx3 = Math.sin(t * 0.7) * 0.3;
  const ry3 = Math.sin(t * 0.3) * 0.6;
  const rz3 = Math.sin(t * 0.2) * 0.2;

  const rx4 = Math.sin(t * 0.7) * 0.2;
  const ry4 = Math.sin(t * 0.3) * 0.2;
  const rz4 = Math.sin(t * 0.2) * -0.3;

  const rx5 = Math.sin(t * 0.7) * -0.4;
  const ry5 = Math.sin(t * 0.3) * 0.2;
  const rz5 = Math.sin(t * 0.2) * 0.1;


  intro.rotation.z = rz1;
  card1.rotation.z = rz1;
  card1.rotation.x = rx1;

  card2.rotation.z = rz2;
  card2.rotation.x = rx2;

  card3.rotation.z = rz3;
  card3.rotation.x = rx3;

  card4.rotation.z = rz4;
  card4.rotation.x = rx4;

  card5.rotation.z = rz5;
  card5.rotation.x = rx5;
};
// animate();


